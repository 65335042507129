import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useLocation, useNavigate } from 'react-router-dom';

const diskTypes = {
  standardHDD: {
    name: 'Standard HDD',
    color: '#f87171',
    sizes: [32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32767],
    performance: {
      32: { iops: 500, throughput: 60 },
      64: { iops: 500, throughput: 60 },
      128: { iops: 500, throughput: 60 },
      256: { iops: 500, throughput: 60 },
      512: { iops: 500, throughput: 60 },
      1024: { iops: 500, throughput: 60 },
      2048: { iops: 500, throughput: 60 },
      4096: { iops: 500, throughput: 60 },
      8192: { iops: 1300, throughput: 300 },
      16384: { iops: 2000, throughput: 500 },
      32767: { iops: 2000, throughput: 500 }
    },
  },
  standardSSD: {
    name: 'Standard SSD',
    color: '#3b82f6',
    sizes: [4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32767],
    performance: {
      4: { iops: 500, throughput: 100 },
      8: { iops: 500, throughput: 100 },
      16: { iops: 500, throughput: 100 },
      32: { iops: 500, throughput: 100 },
      64: { iops: 500, throughput: 100 },
      128: { iops: 500, throughput: 100 },
      256: { iops: 500, throughput: 100 },
      512: { iops: 500, throughput: 100 },
      1024: { iops: 500, throughput: 100 },
      2048: { iops: 500, throughput: 100 },
      4096: { iops: 500, throughput: 100 },
      8192: { iops: 2000, throughput: 400 },
      16384: { iops: 4000, throughput: 600 },
      32767: { iops: 6000, throughput: 750 }
    },
  },
  premiumSSD: {
    name: 'Premium SSD',
    color: '#10b981',
    sizes: [4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32767],
    performance: {
      4: { iops: 120, throughput: 25 },
      8: { iops: 120, throughput: 25 },
      16: { iops: 120, throughput: 25 },
      32: { iops: 120, throughput: 25 },
      64: { iops: 240, throughput: 50 },
      128: { iops: 500, throughput: 100 },
      256: { iops: 1100, throughput: 125 },
      512: { iops: 2300, throughput: 150 },
      1024: { iops: 5000, throughput: 200 },
      2048: { iops: 7500, throughput: 250 },
      4096: { iops: 7500, throughput: 250 },
      8192: { iops: 16000, throughput: 500 },
      16384: { iops: 18000, throughput: 750 },
      32767: { iops: 20000, throughput: 900 }
    },
  },
  premiumSSDv2: {
    name: 'Premium SSD v2',
    color: '#34d399',
    sizes: [4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32767],
    performance: {
      4: { iops: 3000, throughput: 125 },
      8: { iops: 4000, throughput: 1000 },
      16: { iops: 8000, throughput: 2000 },
      32: { iops: 16000, throughput: 4000 },
      64: { iops: 32000, throughput: 8000 },
      128: { iops: 64000, throughput: 10000 },
      256: { iops: 128000, throughput: 12500 },
      512: { iops: 256000, throughput: 15000 },
      1024: { iops: 512000, throughput: 20000 },
      2048: { iops: 1024000, throughput: 20000 },
      4096: { iops: 1600000, throughput: 25000 },
      8192: { iops: 2000000, throughput: 25000 },
      16384: { iops: 2000000, throughput: 25000 },
      32767: { iops: 2000000, throughput: 25000 }
    },
  },
  ultraSSD: {
    name: 'Ultra SSD',
    color: '#8b5cf6',
    sizes: [4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32767, 65536],
    performance: {
      4: { iops: 1200, throughput: 300 },
      8: { iops: 2400, throughput: 600 },
      16: { iops: 4800, throughput: 1200 },
      32: { iops: 9600, throughput: 2400 },
      64: { iops: 19200, throughput: 4900 },
      128: { iops: 38400, throughput: 10000 },
      256: { iops: 76800, throughput: 10000 },
      512: { iops: 153600, throughput: 10000 },
      1024: { iops: 307200, throughput: 10000 },
      2048: { iops: 400000, throughput: 10000 },
      65536: { iops: 400000, throughput: 10000 }
    },
  },
};

const calculatePerformance = (type, size) => {
  const perfData = diskTypes[type].performance;
  const sizes = Object.keys(perfData).map(Number).sort((a, b) => a - b);

  if (type === 'premiumSSDv2') {
    if (size < 6) {
      return { iops: 3000, throughput: 125 };
    } else {
      const iops = 3000 + 500 * (size - 6);
      const throughput = Math.min(125 + 0.25 * (iops - 3000), 1200);
      return { iops, throughput };
    }
  }

  for (let i = 0; i < sizes.length; i++) {
    if (size === sizes[i]) {
      return perfData[sizes[i]];
    }
    if (size < sizes[i]) {
      const lowerSize = sizes[i - 1];
      const upperSize = sizes[i];
      const lowerPerf = perfData[lowerSize];
      const upperPerf = perfData[upperSize];
      const factor = (size - lowerSize) / (upperSize - lowerSize);
      const interpolate = (low, high) => low + factor * (high - low);
      return {
        iops: Math.floor(interpolate(lowerPerf.iops, upperPerf.iops)),
        throughput: Math.floor(interpolate(lowerPerf.throughput, upperPerf.throughput)),
      };
    }
  }
  return perfData[sizes[sizes.length - 1]];
};

const calculatePoolPerformance = (diskType, diskSize, diskCount) => {
  const singleDiskPerf = calculatePerformance(diskType, diskSize);
  return {
    iops: singleDiskPerf.iops * diskCount,
    throughput: singleDiskPerf.throughput * diskCount,
  };
};

const getNextLargerDiskSize = (type, totalSize) => {
  return diskTypes[type].sizes.find(size => size >= totalSize) || diskTypes[type].sizes[diskTypes[type].sizes.length - 1];
};

const AzureVirtualDiskComparisonChart = () => {
  const [selectedType, setSelectedType] = useState('standardSSD');
  const [configurations, setConfigurations] = useState([]);
  const [enabledDiskTypes, setEnabledDiskTypes] = useState(['standardHDD', 'standardSSD', 'premiumSSD']);
  const [isAdminPanelVisible, setIsAdminPanelVisible] = useState(false);
  const [isShareableLinkEnabled, setIsShareableLinkEnabled] = useState(true);
  const [copyButtonText, setCopyButtonText] = useState('Copy Shareable Link');
  const initialLoadDone = useRef(false);
  const copyButtonTimeoutRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  // Load configurations from URL
  useEffect(() => {
    if (!initialLoadDone.current) {
      const searchParams = new URLSearchParams(location.search);
      const configParam = searchParams.get('config');
      if (configParam) {
        try {
          const decodedConfig = JSON.parse(atob(configParam));
          setConfigurations(decodedConfig);
        } catch (error) {
          console.error('Error parsing configuration from URL:', error);
        }
      }
      initialLoadDone.current = true;
    }
  }, [location]);

  // Update URL when configurations change
  const updateURL = useCallback(() => {
    if (initialLoadDone.current) {
      const configString = btoa(JSON.stringify(configurations));
      navigate(`?config=${configString}`, { replace: true });
    }
  }, [configurations, navigate]);

  useEffect(() => {
    updateURL();
  }, [updateURL]);

  const toggleDiskType = (type) => {
    setEnabledDiskTypes(prevState => 
      prevState.includes(type) 
        ? prevState.filter(t => t !== type) 
        : [...prevState, type]
    );
  };

  // Toggle shareable link feature
  const toggleShareableLink = () => {
    setIsShareableLinkEnabled(prev => !prev);
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.shiftKey && e.key === 'A') {
      setIsAdminPanelVisible(prevState => !prevState);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const addDisk = (size) => {
    const newConfig = { type: selectedType, diskSize: size, diskCount: 1 };
    const existingIndex = configurations.findIndex(config => 
      config.type === newConfig.type && config.diskSize === newConfig.diskSize
    );

    if (existingIndex !== -1) {
      const updatedConfigurations = [...configurations];
      updatedConfigurations[existingIndex].diskCount += 1;
      setConfigurations(updatedConfigurations);
    } else {
      setConfigurations([...configurations, newConfig]);
    }
  };

  const removeDisk = (index) => {
    const updatedConfigurations = [...configurations];
    if (updatedConfigurations[index].diskCount > 1) {
      updatedConfigurations[index].diskCount -= 1;
    } else {
      updatedConfigurations.splice(index, 1);
    }
    setConfigurations(updatedConfigurations);
  };

  const resetConfigurations = () => {
    setConfigurations([]);
  };

  const generateShareableLink = () => {
    const currentUrl = window.location.href.split('?')[0];
    const configString = btoa(JSON.stringify(configurations));
    return `${currentUrl}?config=${configString}`;
  };

  const copyShareableLink = () => {
    const link = generateShareableLink();
    
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link)
        .then(() => {
          setCopyButtonText('Link Copied!');
          if (copyButtonTimeoutRef.current) {
            clearTimeout(copyButtonTimeoutRef.current);
          }
          copyButtonTimeoutRef.current = setTimeout(() => {
            setCopyButtonText('Copy Shareable Link');
          }, 2000);
        })
        .catch(err => {
          console.error('Failed to copy link: ', err);
          setCopyButtonText('Failed to Copy');
          setTimeout(() => setCopyButtonText('Copy Shareable Link'), 2000);
        });
    } else {
      // Fallback for browsers that don't support clipboard API
      const textarea = document.createElement('textarea');
      textarea.value = link;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        setCopyButtonText('Link Copied!');
        setTimeout(() => setCopyButtonText('Copy Shareable Link'), 2000);
      } catch (err) {
        console.error('Failed to copy link: ', err);
        setCopyButtonText('Failed to Copy');
        setTimeout(() => setCopyButtonText('Copy Shareable Link'), 2000);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  // Clean up the timeout on component unmount
  useEffect(() => {
    return () => {
      if (copyButtonTimeoutRef.current) {
        clearTimeout(copyButtonTimeoutRef.current);
      }
    };
  }, []);

  const chartData = useMemo(() => {
    return configurations.map((config, index) => {
      const { type, diskSize, diskCount } = config;
      const totalSize = diskSize * diskCount;
      const poolPerf = calculatePoolPerformance(type, diskSize, diskCount);
      const nextLargerSize = getNextLargerDiskSize(type, totalSize);
      const singleDiskPerf = calculatePerformance(type, nextLargerSize);
      
      return {
        name: `${diskCount} x ${diskSize} GB ${diskTypes[type].name}`,
        comparisonName: `vs 1 x ${nextLargerSize} GB ${diskTypes[type].name}`,
        'Virtual Disk IOPS': poolPerf.iops,
        'Single Disk IOPS': singleDiskPerf.iops,
        'Virtual Disk Throughput': poolPerf.throughput,
        'Single Disk Throughput': singleDiskPerf.throughput,
      };
    });
  }, [configurations]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-3 border rounded shadow-lg">
          <p className="font-bold text-gray-700 mb-2">{label}</p>
          <p className="text-sm text-gray-600 font-semibold mb-2">{payload[0].payload.comparisonName}</p>
          {payload.map((entry, index) => (
            <p key={index} className="text-sm" style={{ color: entry.color }}>
              {entry.name}: <span className="font-semibold">{entry.value.toLocaleString()}</span> {entry.name.includes('IOPS') ? 'IOPS' : 'MB/s'}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
    
  const renderChart = (dataKeys, title, unit, showYAxis = true) => (
    <div className="w-1/2 p-2">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            layout="vertical"
            margin={{ left: 20, right: 30, top: 10, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis 
              dataKey="name" 
              type="category" 
              width={120}
              tickFormatter={(value) => {
                return showYAxis ? value.split(' vs ')[0] : '';
              }}
              tick={{ fill: showYAxis ? 'black' : 'transparent' }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey={dataKeys[0]} fill="#3b82f6" name={`Virtual Disk ${unit}`} />
            <Bar dataKey={dataKeys[1]} fill="#10b981" name={`Single Disk ${unit}`} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
  
  const formatPerformance = (iops, throughput) => {
    const formatNumber = (num) => {
      if (num >= 1000000) return `${(num / 1000000).toFixed(1)}M`;
      if (num >= 1000) return `${(num / 1000).toFixed(1)}K`;
      return num.toString();
    };
    return `${formatNumber(iops)} IOPS / ${formatNumber(throughput)} MB/s`;
  };

  return (
    <div className="p-6 max-w-7xl mx-auto bg-gray-50 rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Azure Storage Pool Virtual Disk vs Single Disk Performance Comparison</h1>
      
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 bg-gray-700 text-white p-2 rounded">Step 1: Select drives</h2>
        <div className="flex space-x-4 mb-4">
          {enabledDiskTypes.map(type => (
            <button 
              key={type}
              onClick={() => setSelectedType(type)}
              className={`px-4 py-2 rounded ${selectedType === type ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {diskTypes[type].name}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2 mb-4">
          {diskTypes[selectedType].sizes.map(size => {
            const performance = diskTypes[selectedType].performance[size];
            return (
              <button
                key={size}
                onClick={() => addDisk(size)}
                className="bg-white hover:bg-gray-100 border border-gray-300 p-2 rounded shadow-sm transition duration-150 ease-in-out flex flex-col items-center justify-center text-sm"
              >
                <span className="font-semibold text-lg mb-1">{size} GB</span>
                <span className="text-xs text-gray-600">
                  {formatPerformance(performance.iops, performance.throughput)}
                </span>
              </button>
            );
          })}
        </div>
        <div className="bg-gray-700 p-4 rounded-lg min-h-[100px] flex flex-wrap gap-2 items-start">
          {configurations.map((config, index) => (
            <div key={index} className="bg-gray-600 text-white p-2 rounded flex items-center">
              <span>{config.diskCount} x {config.diskSize}GB {diskTypes[config.type].name}</span>
              <button onClick={() => removeDisk(index)} className="ml-2 text-red-400 hover:text-red-200">✕</button>
            </div>
          ))}
        </div>
        <div className="mt-2 flex justify-between items-center">
          <span>Total number of drives: {configurations.reduce((sum, config) => sum + config.diskCount, 0)}</span>
          <button onClick={resetConfigurations} className="text-blue-500 hover:text-blue-700">Reset</button>
        </div>
      </div>
  
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 bg-gray-700 text-white p-2 rounded">Step 2: Performance Comparison</h2>
        <div className="flex">
          {renderChart(['Virtual Disk IOPS', 'Single Disk IOPS'], 'IOPS Comparison', 'IOPS', true)}
          {renderChart(['Virtual Disk Throughput', 'Single Disk Throughput'], 'Throughput Comparison', 'MB/s', false)}
        </div>
      </div>
  
    {isShareableLinkEnabled && (
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4 bg-gray-700 text-white p-2 rounded">Share Configuration</h2>
        <button 
          onClick={copyShareableLink}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 ease-in-out ${
            copyButtonText === 'Link Copied!' ? 'bg-green-500' : 
            copyButtonText === 'Failed to Copy' ? 'bg-red-500' : ''
          }`}
        >
          {copyButtonText}
        </button>
      </div>
    )}
  
      <div className="text-sm text-gray-600 mt-6">
        <p>Note: These charts compare the theoretical performance of Azure Virtual Disks (composed of multiple smaller disks) 
           against single disks of the next available larger size. Virtual Disk performance is calculated by multiplying the 
           performance of a single disk by the number of disks. Actual performance may vary based on workload patterns, 
           storage spaces configuration, and other factors. The single disk performance shown is for the next available larger 
           disk size that can accommodate the total capacity of the Virtual Disk configuration.</p>
      </div>
  
      {isAdminPanelVisible && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-4 bg-gray-700 text-white p-2 rounded">Admin Panel</h2>
          <div className="flex flex-col space-y-4">
            <div>
              <h3 className="text-lg font-semibold mb-2">Enable/Disable Disk Types</h3>
              <div className="flex space-x-4">
                {Object.keys(diskTypes).map(type => (
                  <button 
                    key={type}
                    onClick={() => toggleDiskType(type)}
                    className={`px-4 py-2 rounded ${enabledDiskTypes.includes(type) ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
                  >
                    {diskTypes[type].name} {enabledDiskTypes.includes(type) ? 'Enabled' : 'Disabled'}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Shareable Link Feature</h3>
              <button 
                onClick={toggleShareableLink}
                className={`px-4 py-2 rounded ${isShareableLinkEnabled ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
              >
                Shareable Link {isShareableLinkEnabled ? 'Enabled' : 'Disabled'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AzureVirtualDiskComparisonChart;